<template>
  <div class="main-content users">
    <h1>Manage Users</h1>

    <div class="filtered-list-subhead">
        <font-awesome-icon class="mobile-filters-button" :icon="showFilters ? 'times-circle' : 'plus-circle'" @click="showFilters = !showFilters" />
        <div class="search-controls">
            <input type="search" v-model="filters.search_term" placeholder="Search..." @search="doSearch()">
            <div class="search-control-button" @click="doSearch()"><font-awesome-icon icon="search" class="search-icon"/></div>
        </div>
        <div></div>
    	<div class="page-controls">
    		<Pagination :paging="paging" @updatePage="goToPage" />
    	</div>
    </div>

    <div class="filtered-list">
        <div v-if="showFilters" class="filtered-list-sidebar">
            <div v-if="detail.user_id" class="filtered-list-sidebar-controls">
                <label>Actions...</label>
        		<div class="button button-dark" @click="sendingEmail = true">Email User</div>
        		<div class="button save" @click="saveItem()"><LabelWithLoadingIcon :loading="saving" label="Save User" /></div>
                <hr>
                <div class="button button-dark" @click="backToList()">Back To List</div>
            </div>
            <div v-else class="filtered-list-sidebar-controls">
                <label>Filters...</label>
                <select v-model="filters.user_type_id" @change="getList()">
                    <option value=''>User Type...</option>
                    <option v-for="userType, index) in userTypes" :value="userType.user_type_id">{{ userType.description }}</option>
                </select>
                <div><input type="checkbox" v-model="filters.locked" @change="getList()"><label>Locked</label></div>
                <div><input type="checkbox" v-model="filters.force_password_change" @change="getList()"><label>Force Password Change</label></div>
                <div><input type="checkbox" v-model="filters.inactive" @change="getList()"><label>Deleted</label></div>
                <div :class="['button', hasFilters ? 'button-dark' : 'disabled']" @click="clearFilters()">Clear Filters</div>
                <hr>
                <label>Actions...</label>
                <div class="button button-dark" @click="newItem()">Create A User</div>
                <div :class="['button', list.length > 0 ? 'button-dark' : 'disabled']" @click="doExport()">Export List</div>
                <div class="note">{{ filterMessage }}</div>
            </div>

        </div>
        <div v-if="!detail.user_id" class="filtered-list-content">
            <table class="filtered-list-table">
                <tr class="clickable-list-header">
                    <th>Contact</th>
                    <th>Username / Type</th>
                    <th>Last Login</th>
                </tr>
                <tr v-for="(item, index) in list" class="clickable-list" @click="getItemRoute(item.user_id)">
                    <td>
                        {{ localGetFullName(item) }}<br>
                        {{ localGetAddressLine(item) }}
                    </td>
                    <td>
                        {{ item.user_name }}<br>
                        {{ localCapitalizeWords(item.user_type_code) }}
                    </td>
                    <td>
                        {{ this.localDateFromDateTime(item.last_login) }}<br>
                        {{ this.localTimeFromDateTime(item.last_login) }}
                    </td>
                </tr>
            </table>
        </div>
        <div v-else class="filtered-list-item">
            <div class="filtered-list-item-two-columns">
                <ContactForm :contact="detail" :required="['first_name', 'last_name', 'email_address']" @update="updateItemFromChild" :key="detail.contact_id" />
                <div>
                    <div class="section-heading">User Details</div>
                    <LabelRequired label="Username" :condition="detail.user_name"/>
                    <input type="text" v-model="detail.user_name"/>
                    <label>New Password</label>
                    <PasswordInput v-model="detail.new_password" />
                    <label>Retype Password</label>
                    <PasswordInput v-model="detail.retype_password" />
                    <LabelRequired label="User Type" :condition="detail.user_type_id"/>
                    <select v-model="detail.user_type_id">
                        <option value=''>Select...</option>
                        <option v-for="userType, index) in userTypes" :value="userType.user_type_id">{{ userType.description }}</option>
                    </select>
                    <label>Last Login</label>
                    <input type="text" v-model="detail.last_login" disabled>
                    <label>Last Password Change</label>
                    <input type="text" v-model="detail.last_password_change" disabled>
                    <div class="chekbox-with-label">
                        <input type="checkbox" v-model="detail.force_password_change" true-value="1" false-value="0"> <label>Force Password Change</label>
                    </div>
                    <div class="chekbox-with-label">
                        <input type="checkbox" v-model="detail.locked" true-value="1" false-value="0"> <label>Locked</label>
                    </div>
                    <div class="chekbox-with-label">
                        <input type="checkbox" v-model="detail.inactive" true-value="1" false-value="0"> <label>Deactivated</label>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <MessageModal v-if="message" :message="message" :loading="loading" :errorStatus="errorStatus" @dismiss="message = ''" />

    <SendEmail v-if="sendingEmail" :contact_id="detail.contact_id" @cancel="sendingEmail = false" @sent="emailSent++; sendingEmail = false"/>

    <iframe id="download" src="" style="display: none;"></iframe>

</div>

</template>

<script>
import Pagination from '@/components/Pagination.vue'
import ContactForm from '@/components/ContactForm'
import LabelRequired from '@/components/LabelRequired.vue'
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import PasswordInput from '@/components/PasswordInput.vue'
import SendEmail from '@/components/SendEmail.vue'
import MessageModal from '@/components/MessageModal.vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
	name: 'users',
    components: {
        Pagination,
        ContactForm,
        LabelRequired,
        LabelWithLoadingIcon,
        PasswordInput,
        SendEmail,
        MessageModal
    },
	data() {
		return {
			loading: false,
			userTypes: [],
            userId: '',
			filters: {
                page: 1,
				search_term: '',
				user_type_id: '',
                force_password_change: '',
                locked: '',
				inactive: '',
			},
            showFilters: false,
			paging: {},
			list: [],
			detail: {},
            message: '',
            errorStatus: false,
            filterMessage: '',
            saving: false,
            saved: false,
            sendingEmail: false,
            emailSent: 1
        }
    },
    created() {
        this.showFilters = window.matchMedia("(min-width: 768px)").matches
		this.getUserTypes()
        this.userId = this.$route.params.user_id
		if (this.userId) {
			this.getItem(this.userId)
		} else {
            this.getList()
        }
    },
    watch: {
        '$route.params.user_id': function (userId) {
            if (userId) {
                this.userId = userId
                this.getItem(userId)
            } else {
                this.detail = {}
                this.userId = ''
                this.saved = false
                this.getList()
            }
        }
    },
	computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getUser',
            'getDownloadEndpoint'
        ]),
		hasFilters() {
			for (const [key, value] of Object.entries(this.filters)) {
				if (['search_term', 'user_type_id', 'force_password_change', 'locked', 'inactive'].includes(key) && value) {
					return true
				}
			}
			return false
		}
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        localCapitalizeWords(data) {
            return this.caplitalizeWords(data)
        },
        localDateFromDateTime(datetime) {
            return this.dateFromDateTime(datetime)
        },
        localTimeFromDateTime(datetime) {
            return this.timeFromDateTime(datetime)
        },
        localGetFullName(data) {
            return this.getFullName(data)
        },
        localGetAddressLine(data) {
            return this.getAddressLine(data)
        },
        getUserTypes() {
            this.apiCall({ destination: 'get_list_user_types', data: { manager_flag: this.filters.manager_flag } }).then(obj => {
                this.userTypes = obj.user_types
            }).catch(err => {
                console.log(err);
            })
        },
		getList() {
			if (this.loading) { return }
		    this.message = this.filters.do_export ? 'Getting the export list...' : ''
			this.loading = true
			this.apiCall({ destination: 'get_list_users', data: this.filters }).then(obj => {
				this.loading = false
				if (obj.status == 'success') {
                    if (this.filters.do_export) {
        				// set hidden iframe src to the API's download URL
        				document.getElementById("download").src = this.getDownloadEndpoint + "?file_name=" + obj.file_name + "&output_file_name=" + obj.file_name
        				this.message = obj.total_items + ' items exported'
        				// var timer = setTimeout((function() {
        				// 	this.filterMessage = ''
        				// }.bind(this)),3000)
                        this.filters.do_export = false
                    } else {
                        this.detail = {}
    					this.list = obj.list
    					this.paging = obj.paging
                        window.scrollTo(0, 0);
                    }
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
			}).catch(err => {
				console.log(err)
			})
		},
        doExport() {
			this.message = 'Generating export file...'
            this.filters.do_export = true
            this.getList()
        },
        clearFilters() {
            this.filters = {
                page: 1,
                search_term: '',
                user_type_id: '',
                force_password_change: '',
                locked: '',
				inactive: '',
            }
            this.getList()
        },
        doSearch() {
            if (this.userId) {
                this.$router.push('/users')
            }
            this.getList()
        },
		goToPage(page) {
			this.filters.page = page
			this.getList()
		},
        getItemRoute(userId) {
            this.$router.push('/users/detail/' + userId)
        },
        getItem(userId) {
            if (this.loading) { return }
		    this.message = ''
            this.errorStatus = true
            if (this.userId == 'new') {
                // user_id is new so set a new detail object
    			this.detail = {
                    user_id: 'new',
    				user_type_code: '',
    				user_name: '',
    				new_password: '',
    				user_type_id: '',
    				administrator_flag: ''
    			}
                // query contact_id means we're attaching a user to an existing contact
                if (this.$route.query.contact_id) {
        			this.loading = true
            		this.apiCall({ destination: 'get_item_contact', data: { contact_id: this.$route.query.contact_id } }).then(obj => {
        				this.loading = false
            			if (obj.status == 'success') {
                            // is there already a user attached to this contact?
                            if (obj.detail.user_id) {
                                this.$router.push('/users/detail/' + obj.detail.user_id)
                            } else {
                    			for (const [key, value] of Object.entries(obj.detail)) {
                                    if (key != 'user_id') {
                                        this.detail[key] = value
                                    }
                    			}
                            }
            			} else {
        					this.message = obj.message
                            this.errorStatus = true
        				}
            		})
                }
                return
            }
			this.loading = true
    		this.apiCall({ destination: 'get_item_user', data: { user_id: userId } }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.detail = obj.detail
                    window.scrollTo(0, 0);
    			} else {
					this.message = obj.message
                    this.errorStatus = true
                }
    		})
        },
        newItem() {
            this.$router.push('/users/detail/new')
        },
        updateItemFromChild(contactCopy) {
            // ContactForm emits an update event when keyup is fired
			for (const [key, value] of Object.entries(contactCopy)) {
                this.detail[key] = value
			}
        },
        saveItem() {
            if (this.saving) { return }
		    this.message = ''
            this.errorStatus = false
			if (!this.detail.first_name || !this.detail.last_name) {
				this.message = 'First and Last Name are required'
                this.errorStatus = true
				return
			}
			if (!this.detail.email_address || !this.isValidEmailAddress(this.detail.email_address)) {
				this.message = 'Complete Email Address required'
                this.errorStatus = true
				return
			}
            if (!this.detail.user_name) {
                this.message = 'Username is required'
                this.errorStatus = true
                return
            }
            if (this.detail.user_id == 'new' && !this.detail.new_password && !this.detail.retype_password) {
                this.message = 'New Password and Retype Password are required'
                this.errorStatus = true
                return
            }
            if (this.detail.new_password && this.detail.retype_password != this.detail.new_password) {
                this.message = 'New Password and Retype Password must match'
                this.errorStatus = true
                return
            }
            if (this.detail.retype_password && this.detail.retype_password != this.detail.new_password) {
                this.message = 'New Password and Retype Password must match'
                this.errorStatus = true
                return
            }
            if (!this.detail.user_type_id) {
                this.message = 'User Type is required'
                this.errorStatus = true
                return
            }
            // first save contact info
            if (this.saving) { return }
			this.saving = true
    		this.apiCall({ destination: 'validate_new_user_name', data: { user_id: this.detail.user_id, user_name: this.detail.user_name, password: this.detail.new_password } }).then(obj => {
    			if (obj.status == 'success') {
            		this.apiCall({ destination: 'save_item_contact', data: this.detail }).then(obj => {
            			if (obj.status == 'success') {
                            this.detail.contact_id = obj.contact_id
                            let userData = {
                                user_id: this.detail.user_id,
                                contact_id: this.detail.contact_id,
                                user_name: this.detail.user_name,
                                new_password: this.detail.new_password,
                                user_type_id: this.detail.user_type_id,
                                force_password_change: this.detail.force_password_change,
                                locked: this.detail.locked,
                                inactive: this.detail.inactive
                            }
                    		this.apiCall({ destination: 'save_item_user', data: userData }).then(obj => {
                				this.saving = false
                    			if (obj.status == 'success') {
                                    this.detail.user_id = obj.user_id
                                    this.message = 'User saved.'
                                    setTimeout(() => { this.message = '' }, 2000)
                                    this.saved = true
                                    if (this.detail.user_id != this.userId) {
                                        this.$router.push('/users/detail/' + this.detail.user_id)
                                    }
                                } else {
                                    this.message = obj.message
                                    this.errorStatus = true
                                }
                            })
            			} else {
        				    this.saving = false
        					this.message = obj.message
                            this.errorStatus = true
        				}
            		})
                } else {
    				this.saving = false
                    this.message = obj.message
                    this.errorStatus = true
                }
            })
        },
        backToList() {
            this.$router.push('/users')
        }
    }
}
</script>
<style lang="scss" scoped>
    .filtered-list-item-two-columns {
        grid-template-columns: 1fr 40%;
    }
</style>
